var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-container d-flex content align-items-center navScroll"},[_c('b-sidebar',{ref:"mySidebar",attrs:{"id":"sidebar-right","bg-variant":"white","sidebar-class":"sidebar-lg","right":"","no-header":"","backdrop":"","shadow":""},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(
          this.waObj != null &&
          this.waObj.qrCode == '' &&
          !this.waObj.connected
        )?_c('h5',{staticClass:"mb-0"},[_vm._v(" Connecting to WhatsApp ⏳ ")]):_vm._e(),(this.waObj != null && this.waObj.qrCode != '')?_c('h5',{staticClass:"mb-0"},[_vm._v(" Please Scan the QR Code 📱 ")]):_vm._e(),(this.waObj != null && this.waObj.connected)?_c('h5',{staticClass:"mb-0"},[_vm._v(" Connected ✔️ ")]):_vm._e(),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.hideSideBar()}}})],1),_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[(
            this.waObj == null ||
            (this.waObj.qrCode == '' && !this.waObj.connected)
          )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticStyle:{"height":"300px"},attrs:{"md":"12"}},[_c('lottie-animation',{attrs:{"path":"wa-loading.json"}})],1),(false)?_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.disRetry},on:{"click":function($event){return _vm.loadRetry()}}},[_vm._v(" Retry "+_vm._s(_vm.retryCount < 10 ? ("(00 : 00 : 0" + _vm.retryCount + ")") : ("(00 : 00 : " + _vm.retryCount + ")"))+" ")]):_vm._e()],1):_vm._e(),(this.waObj != null && this.waObj.connected)?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticStyle:{"height":"300px"},attrs:{"md":"12"}},[_c('lottie-animation',{attrs:{"path":"connected.json"}})],1),(_vm.showSendMsg)?_c('b-button',{attrs:{"to":{ name: 'apps-chat' },"variant":"success","block":""}},[_vm._v(" Start Sending Messages ")]):_vm._e()],1):_vm._e(),(
            this.waObj != null &&
            this.waObj.qrCode != '' &&
            !this.waObj.connected
          )?_c('b-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"md":"12"}},[_c('div',{attrs:{"md":"12"}},[_c('div',{attrs:{"md":"12"}},[_c('video',{staticStyle:{"width":"98%"},attrs:{"autoplay":"true","controls":"","controlslist":"nodownload"}},[_c('source',{attrs:{"src":"https://cdn.cloudious.net/file-1676857951060-6456090.mp4","type":"video/mp4"}})])])])]):_vm._e(),(
            this.waObj != null &&
            this.waObj.qrCode != '' &&
            !this.waObj.connected
          )?_c('b-col',{staticClass:"mt-5",staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"md":"12"}},[_c('div',{attrs:{"md":"12"}},[_c('b-img',{staticStyle:{"width":"300px","height":"300px","display":"flex"},attrs:{"src":this.waObj.qrCode,"fluid":"","alt":"qr_code"}})],1)]):_vm._e(),(false)?_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"i",rawName:"v-i"}],staticClass:"mt-2 ml-2 mr-2",staticStyle:{"width":"90%"},attrs:{"variant":"primary","disabled":_vm.qrLoading},on:{"click":function($event){return _vm.GetQRCode()}}},[(_vm.qrLoading)?_c('div',[_c('b-spinner',{attrs:{"small":"","variant":"light","type":"grow","label":"Spinning"}})],1):_c('span',[_vm._v("Get New QR Code")])])],1):_vm._e()],1)],1),_c('sidebar-content')],1),_c('b-modal',{attrs:{"id":"modal-campuses","cancel-variant":"outline-secondary","hide-footer":"","centered":"","title":"Business","body-class":"p-0"}},[_c('div',{staticClass:"pt-2 pl-2 pr-2 pb-0"},[_c('b-form-group',{ref:"search",attrs:{"label":"Search"}},[_c('b-form-input',{attrs:{"placeholder":"enter name to search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('div',{staticClass:"scrollContent"},_vm._l((_vm.filteredOptions),function(item){return _c('h5',{key:item.id,staticClass:"pt-50 pb-50 pl-2 pr-2 font-weight-300 mb-0 cursor-pointer",class:[
          item.id == _vm.current && 'text-white bg-primary',
          item.id != _vm.current && 'search-type' ]},[_vm._v(" "+_vm._s(item.branch)+" ")])}),0)]),_c('ul',{staticClass:"nav navbar-nav d-xl-none"},[_c('li',{staticClass:"nav-item"},[_c('b-link',{staticClass:"nav-link",on:{"click":_vm.toggleVerticalMenuActive}},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"21"}})],1)],1)]),_c('div',{staticClass:"d-flex align-items-center justify-content-start"},_vm._l((_vm.menuItems),function(item){return _c('b-button',{key:item,staticClass:"mr-1 btn-icon",class:[_vm.routeVariant(item.route) && 'btn-gradient-primary'],attrs:{"to":{ name: item.route },"variant":_vm.routeVariant(item.route) ? 'outline-light' : 'outline-secondary'}},[(_vm.textAdjust)?_c('span',[_vm._v(_vm._s(item.title.substr(0, 3)))]):_c('span',[_vm._v(_vm._s(item.title))])])}),1),_c('b-navbar-nav',{staticClass:"nav align-items-center justify-content-end ml-auto adjustAlign"},[(_vm.campusList.length > 1)?_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"adjust-dropdown",attrs:{"split":"","size":"sm","variant":"outline-primary","text":_vm.branchName,"right":"","disabled":_vm.changingDb}},[_vm._l((_vm.campusList),function(d,ind){return _c('b-dropdown-item',{key:ind,attrs:{"active":_vm.current == d.id && _vm.$store.state.userData.db == d.organizationType
            ? true
            : false},on:{"click":function($event){return _vm.ChangeDB(d)}}},[_vm._v(" "+_vm._s(d.branch)+" ")])}),_c('b-dropdown-divider')],2):(
        this.$store.state.userData.roles == 'administrator' ||
        this.$store.state.userData.roles == 'admin' ||
        _vm.showCampus
      )?_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"adjust-dropdown",attrs:{"split":"","size":"sm","variant":"outline-primary","text":_vm.branchName,"right":""}},[_vm._l((_vm.branches),function(d,ind){return _c('b-dropdown-item',{key:ind,attrs:{"active":_vm.current == d.id ? true : false},on:{"click":function($event){return _vm.ChangeBranch(d)}}},[_vm._v(" "+_vm._s(d.branch)+" ")])}),_c('b-dropdown-divider')],2):_vm._e(),(_vm.showWhatsapp)?_c('div',{staticClass:"ml-1 mr-1 position-relative text-center",staticStyle:{"width":"30px","height":"30px","cursor":"pointer"}},[(_vm.statusLoading)?_c('b-spinner',{attrs:{"small":"","variant":"primary","label":"loading"}}):_c('div',{on:{"click":function($event){return _vm.showSidebar()}}},[_c('b-img',{attrs:{"src":_vm.waIcon,"fluid-grow":"","alt":"whatsapp"}}),(
            _vm.statusW.toLowerCase() == 'connected' ||
            _vm.statusW.toLowerCase() == 'OPENING'
          )?_c('feather-icon',{staticClass:"text-success status-icons",attrs:{"icon":"CheckIcon","size":"18"}}):_c('feather-icon',{staticClass:"text-danger status-icons",attrs:{"icon":"XIcon","size":"18"}})],1)],1):_vm._e(),(_vm.notiObj && _vm.notiObj.valueBool)?_c('notification-dropdown-new',{attrs:{"data":_vm.notificationData}}):_vm._e(),_c('user-dropdown')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
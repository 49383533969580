<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-row class="p-2">
        <b-col md="12">
          <b-form-group
            label="Logo"
            invalid-feedback="Logo are required"
            required
            ref="logo"
          >
            <div>
              <b-spinner
                v-if="logoLoading"
                type="grow"
                label="Loading..."
                variant="primary"
                class="m-5"
              />
              <b-img
                v-else
                ref="previewEl"
                :src="logo"
                style="border-radius: 10px"
                height="100px"
                width="100px"
                @click="$refs.logoInput.click()"
              />

              <input
                type="file"
                id="logoInput"
                hidden
                ref="logoInput"
                @change="addLogo()"
                accept="image/*"
              />
            </div>
          </b-form-group>
        </b-col>
        <!-- <b-col md="12">
          <b-form-group
            label="Choose color"
            invalid-feedback="Color is required"
          >
            <b-form-input type="color" v-model="colorInput" @input="testing" />
          </b-form-group>
        </b-col> -->
        <b-col md="12">
          <b-button
            variant="primary"
            block
            :disabled="request"
            @click="testing"
          >
            <b-spinner v-if="request" small type="grow" />
            <span v-else> Save </span>
          </b-button>
        </b-col>
      </b-row>
      <sidebar-content />
    </b-sidebar>

    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <!-- <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.fullName || userData.username }}
          </p>
          <span class="user-status">{{ userData.roles }}</span>
        </div> -->
        <!-- :src="userData.avatar" -->

        <b-avatar
          size="40"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <!-- v-if="!userData.fullName" -->

          <feather-icon icon="UserIcon" size="22" />
        </b-avatar>
      </template>

      <!-- <b-dropdown-item
        :to="{ name: 'pages-profile'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Profile</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'apps-email' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MailIcon"
          class="mr-50"
        />
        <span>Inbox</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'apps-todo' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CheckSquareIcon"
          class="mr-50"
        />
        <span>Task</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'apps-chat' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MessageSquareIcon"
          class="mr-50"
        />
        <span>Chat</span>
      </b-dropdown-item> -->

      <!-- <b-dropdown-divider /> -->
      <!-- 
      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-pricing' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CreditCardIcon"
          class="mr-50"
        />
        <span>Pricing</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-faq' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="HelpCircleIcon"
          class="mr-50"
        />
        <span>FAQ</span>
      </b-dropdown-item> -->

      <!-- <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="visibility = true"
      >
        <span>Settings</span>
      </b-dropdown-item> -->
      <b-dropdown-item link-class="d-flex align-items-center" @click="change">
        <feather-icon size="16" icon="LockIcon" />
        <span>Change Password</span>
      </b-dropdown-item>
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BImg,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { $themeConfig, $themeColors } from "@themeConfig";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSidebar,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BImg,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
  data() {
    return {
      userData: this.$store.state.userData,
      avatarText,
      pressed: true,
      visibility: false,
      request: false,
      colorInput: "",
      logoLoading: false,
      logo: this.$store.state.appConfig.appLogoImage,
    };
  },
  created() {
    // console.log($themeColors);
    // console.log(this.userData);
    // console.log(this.$store.state.userData.roles);
  },
  mounted() {
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  methods: {
    handleEvent(event) {
      // console.log(event.key);
      if (event.ctrlKey && event.keyCode === 76) {
        event.preventDefault();
        if (this.pressed) {
          this.pressed = false;
          this.logout();
        }
      }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
      // this.$store.commit("setMenu", []);
      // this.$store.commit("setCurrentMenu", {});

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);
      this.$store.commit("setPassword", "");
      this.$store.commit("setEncPassword", "");
      this.$store.commit("setDBList", []);
      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    change() {
      this.$router.push("/reset-password");
    },
    addLogo() {
      let logo = this.$refs.logoInput.files[0];
      if (logo != "") {
        this.logoLoading = true;
        let formData = new FormData();
        formData.append("file", logo);
        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.logo = resp;
            this.logoLoading = false;
          });
      }
    },
    testing() {
      this.$store.commit("appConfig/UPDATE_APP_LOGOIMAGE", this.logo);
    },
  },
};
</script>
